module.exports = {
    pl: {
      links: {
        contact_us: 'Skontaktuj się',
        meet_us: 'Poznaj nas',
        more: 'Dowiedz się więcej',
        home: 'Strona główna',
        about: {
          title: 'O nas',
          geneza: 'Geneza',
          wartosci: 'Wartości',
          mozliwosci: 'Nasze możliwości',
          standardy: 'Standardy',
          zespol: 'Zespół'
        },
        services: 'Usługi',
        TCP: 'Transport chemii płynnej',
        TMN: 'Transport materiałów niebezpiecznych',
        contact: 'kontakt'
      },
      footer: {
        name:
          'BENSKI TRANSPORT © 2018 Transport specjalistyczny ADR, Transport międzynarodowy',
        email: 'lp.iksneb@oruib',
        privacy_policy: 'Privacy policy',
        legal: 'Legal'
      },
  
      /* Home page */
      home: {
        section_1: {
          title: 'Bezpiecznie do celu od ponad 30 lat',
          paragraph:
            'Bezpieczeństwo stanowi naczelną zasadę naszej Firmy. Dokładamy wszelkich starań by było ono obecne na każdym etapie zlecenia – zapewniamy je zarówno w stosunku do ludzi, jaki przewożonych ładunków.'
        },
        section_2: {
          title: 'O nas - jak to się zaczęło?',
          paragraph:
            'Rok 1986 to czas kiedy zaczęliśmy stawiać pierwsze, zdecydowane kroki w branży transportowej. Wraz z każdym przejechanym kilometrem zbliżaliśmy się coraz bardziej do pełnej perfekcji w dziedzinie transportu specjalistycznego.'
        },
        section_3: {
          title:
            'Potrzebujesz transportu materiałów niebezpiecznych lub chemii płynnej?',
          paragraph: 'Chętnie odpowiemy na każde pytania!'
        },
        section_4: {
          title: 'Nasze usługi',
          subtitle:
            'Oferujemy transport materiałów wybuchowych, broni i amunicji.',
          button_1: 'Transport materiałów wybuchowych',
          paragraph_1:
            'Specjalizujemy się również w transporcie produktów chemicznych w cysternach i kontenerach renomowanych producentów.',
          button_2: 'Transport chemii płynnej',
          paragraph_2:
            'Dysponujemy najwyższej klasy sprzętem specjalistycznym, nasze pojazdy wyposażyliśmy m.in. w system lokalizacji GPS, a cała flota jest regularnie serwisowana w autoryzowanych punktach, dzięki czemu utrzymujemy doskonały stan techniczny samochodów.'
        },
        section_5: {
          title: 'Społeczna odpowiedzialność',
          paragraph_1:
            'W trakcie wieloletniej działalności Firma Benski kierowała i kieruje się zasadami społecznej odpowiedzialności biznesu.',
          paragraph_2: 'Oznacza to, że szczególną wagę przykładamy do:',
          boxes: {
            box_1: {
              title: 'Zasobów ludzkich',
              paragraph:
                'Zespół naszych kierowców składa się ze specjalistów, którzy posiadają umiejętności z zakresu ekonomicznej jazdy oraz redukcji spalin. Współpraca z nami oznacza gwarancję bezpieczeństwa na wielu płaszczyznach.'
            },
            box_2: {
              title: 'Ochrony środowiska',
              paragraph:
                'Flota, którą dysponujemy odznacza się niską produkcją zanieczyszczeń i ekonomicznym zużyciem paliwa. Wszystkie nasze pojazdy spełniają restrykcyjne normy dotyczące emisji spalin, w tym EURO 5 i EURO 6.'
            },
            box_3: {
              title: 'Relacji z Klientami',
              paragraph:
                'Wykorzystujemy najnowocześniejsze systemy monitorowania, by zagwarantować naszym Klientom skuteczne dostarczenie ładunku – bez względu na rodzaj przewożonych materiałów, czy destynację.'
            }
          }
        },
        section_6: {
          /* Each element in array will be separated by new line on the page */
          title: ['BENSKI sp. z o.o. sp. k.', 'ul. Niegolewskich 5, 42-700 Lubliniec'],
          /* Each element will be new paragraph, if it is array, the elements will be separated by new line */
          paragraphs: [
            ['lp.iksneb@oruib'],
            'T: +48 34 351 30 27',
            'Nasi konsultanci są do Państwa usług',
            'Pracujemy od 8:00 do 16:00 w dni robocze.'
          ]
        }
      },
  
      /* About page */
      about: {
        section_1: {
          title: 'O nas - jak to się zaczęło?',
          paragraph:
            'Rok 1986 to czas kiedy zaczęliśmy stawiać pierwsze, zdecydowane kroki w branży transportowej. Wraz z każdym przejechanym kilometrem zbliżaliśmy się coraz bardziej do pełnej perfekcji w dziedzinie transportu specjalistycznego.'
        },
        section_2: {
          title: 'Geneza i wartości firmy Benski',
          paragraph_1:
            'Od samego początku rodzinny charakter Firmy i polski kapitał wyznaczają wartości, którymi się kierujemy.',
          paragraph_2:
            'Stawiamy na najwyższą jakość świadczonych usług, dlatego poznajemy potrzeby naszych Klientów i niezmiennie słuchamy Ich sugestii już od kilkudziesięciu lat. Takie podejście doprowadziło do szybkiej rozbudowy Firmy – dynamiczny rozwój to w naszym przypadku nie tylko puste hasło - to efekt pełnego zaangażowania ojca i syna, właścicieli Firmy Benski.',
          paragraph_3:
            'Ponad 30 lat aktywności na rynku transportowym pozwoliły na osiągnięcie perfekcji w spedycji międzynarodowej i wypracowanie zaufania zarówno klientów indywidualnych, jak i największych światowych armii.'
        },
        section_3: {
          title: 'Potrzebujesz transportu chemii płynnej?',
          paragraph: 'Chętnie odpowiemy na każde pytania!'
        },
        section_4: {
          title: 'Dbamy o detale',
          paragraph:
            'Każde ze zrealizowanych w przeciągu ponad 30 lat zlecenie traktowane było przez nas z największą uwagą i dbałością o najmniejszy detal na każdym etapie realizacji. Nie stosujemy podziałów na ważne i mniej ważne projekty, każde z kilku tysięcy zleceń rocznie otrzymuje najwyższy priorytet.'
        },
        section_5: {
          title: 'Nasze możliwości',
          paragraph:
            'Nasze umiejętności są wsparte wysokiej jakości zapleczem technicznym. Tylko takie połączenie umożliwia profesjonalną realizację kilku tysięcy zleceń rocznie. Flota Firmy Benski pozwala na organizację krajowych i międzynarodowych przewozów materiałów niebezpiecznych wszystkich klas ADR, również klasy 1, czyli materiałów i przedmiotów wybuchowych.',
          examples: {
            title: 'Oferujemy przewóz:',
            name_1: 'Izotermami o ładowności do 1, 8 oraz 24 ton',
            name_2:
              'Cysternami oraz IZO-kontenerami o pojemności 30000 - 34000 litrów',
            name_3: 'Kontenerów 20- i 40-stopowych',
            name_4: 'Towarów pod zamknięciem celnym z wykorzystaniem karnetów TIR'
          }
        },
        section_6: {
          title: 'Niezmienne standardy',
          paragraph_1:
            'Niezależnie od tego, czy przewozimy 10 kg. prochu czarnego do magazynu oddalonego o 100 km, czy wielotonowe ładunki do Klientów w Turcji, działamy z zachowaniem tych samych procedur i jakości świadczonych usług.',
          paragraph_2:
            'Zarówno Klient indywidualny, jak i największe światowe armie od lat powierzają nam zlecenia o różnym stopniu skomplikowania, w zamian otrzymują gwarancję profesjonalnej oraz terminowej realizacji.'
        },
        team: {
          title: 'Nasz zespół',
          section_1: {
            title: 'Dział logistyki',
            names: [
              {
                name_1: 'Mateusz Mańka',
                name_2: 'Transport / spedycja - cysterny',
                line_1: 'E-mail: m.manka@benski.pl',
                line_2: 'Telefon / Fax: +48 34 351 30 27',
                line_3: 'Komórka: +48 668 441 029',
                line_4: 'E-mail: ',
                line_5: 'lp.iksneb@aknam.m'
              },
              {
                name_1: 'Kamil Krawczyk',
                name_2: 'Transport / spedycja - cysterny',
                line_1: 'E-mail: k.krawczyk@benski.pl',
                line_2: 'Telefon / Fax: +48 34 351 30 27',
                line_3: 'Komórka: +48 518 183 146',
                line_4: 'E-mail: ',
                line_5: 'lp.iksneb@kyzcwark.k'
              },
              {
                name_1: 'Andrzej Zoń',
                name_2: 'Transport / spedycja - izotermy',
                line_1: 'E-mail: a.zon@benski.pl',
                line_2: 'Telefon / Fax: +48 34 351 30 27',
                line_3: 'Komórka: +48 668 553 843',
                line_4: 'E-mail: ',
                line_5: 'lp.iksneb@noz.a'
              },
              {
                name_1: 'Logistic Manager / ',
                name_2: ' Vice-Chairman of the Board',
                bold_line_1: 'Michał Benski',
                line_2: 'E-mail: michal@benski.pl',
                line_3: 'Telefon: +48 602 345 195',
                line_4: 'E-mail: ',
                line_5: 'lp.iksneb@lahcim'
              }
            ]
          },
          section_2: {
            title: 'Księgowość',
            names: [
              {
                name: 'Jolanta Wiśniowska',
                line_1: 'Telefon: +48 34 356 12 01',
                line_2: 'Fax: +48 34 351 30 27',
                line_8: 'E-mail: jolanta.wisniowska@benski.pl',
                line_6: 'E-mail: ',
                line_7: 'lp.iksneb@akswoinsiw.atnaloj'
              },
              {
                name: 'Mariola Zozgórnik',
                line_1: 'Telefon: +48 34 356 12 01',
                line_2: 'Fax: +48 34 351 30 27',
                line_8: 'E-mail: mariola.zozgornik@benski.pl',
                line_6: 'E-mail: ',
                line_7: 'lp.iksneb@kinrogzoz.aloiram'
              },
              {
                name: 'BENSKI sp. z o.o. sp. k.',
                line_1: 'ul. Strażacka 27',
                line_2: '42-700 Solarnia',
                line_3: 'NIP 5751886314',
                line_4: 'T: +48 34 351 30 27',
                line_5: 'E: biuro@benski.pl',
                line_6: 'E: ',
                line_7: 'lp.iksneb@oruib'
              },
              {
                name: 'Adres korespondencyjny',
                line_1: 'BENSKI sp. z o.o. sp. k.',
                line_2: 'ul. Niegolewskich 5,',
                line_3: '42-700 Lubliniec'
              }
            ]
          }
        }
      },
  
      /* TCP page */
      TCP: {
        section_1: {
          title: 'Transport chemii płynnej',
          paragraph:
            'Kompleksowe podejście i profesjonalizm gwarantem bezpiecznego transportu Twojego towaru.'
        },
        section_2: {
          title: 'Transport produktów chemicznych',
          paragraph:
            'Oferujemy transport produktów chemicznych w cysternach i kontenerach renomowanych producentów.'
        },
        section_3: {
          title: 'Wieloletnie doświadczenie w transporcie chemii płynnej',
          paragraph_1:
            'Przepisy krajowe i międzynarodowe nakładają w tym zakresie na firmę transportową szereg wymogów i obwarowań.',
          paragraph_2:
            'Mimo wysokiego stopnia skomplikowania zleceń tego typu, nasze kompleksowe podejście oraz profesjonalizm pozwalają na sprawną realizację każdego projektu.',
          paragraph_3:
            'Przewóz chemii płynnej wymaga również najwyższych kompetencji od kierowców i pracowników zaangażowanych w proces, nasz zespół posiada wszelkie uprawnienia zapewniające pełne bezpieczeństwo w transporcie chemii płynnej.'
        },
        section_4: {
          title: 'Standardy przewozu',
          paragraph:
            'Ścisłe utrzymywanie standardów bezpieczeństwa to naczelna zasada, którą kierujemy się przy przewozach chemii płynnej. Ładunki chemiczne posiadają różne właściwości, wymagają specjalistycznej infrastruktury, zapewnienia szczególnych procedur logistycznych oraz odpowiednich warunków przewozu, każdorazowo przy zleceniach tego typu dbamy więc o:',
          icons: {
            icon_1: 'Odpowiednie oznakowanie cystern i pojazdów',
            icon_2: 'Wyszkolenie kierowców i personelu',
            icon_3: 'Opracowanie i dostarczenie wymaganej dokumentacji',
            icon_4:
              'Zapewnienie odpowiednich warunków przewozu i wyposażenia pojazdów'
          }
        },
        section_5: {
          title: 'Potrzebujesz transportu chemii płynnej?',
          paragraph: 'Chętnie odpowiemy na każde pytania!'
        }
      },
  
      /* TMN page */
      TMN: {
        section_1: {
          title: 'Transport materiałów wybuchowych, broni i amunicji',
          paragraph:
            'Kompleksowe podejście i profesjonalizm gwarantem bezpiecznego transportu Twojego towaru.'
        },
        section_2: {
          title: 'Transport materiałów niebezpiecznych',
          paragraph:
            'Dysponujemy najwyższej klasy sprzętem specjalistycznym do transportu materiałów wybuchowych.'
        },
        section_3: {
          title: 'Kompleksowy transport broni, amunicji i materiałów wybuchowych',
          paragraph_1:
            'Benski specjalizuje się w transporcie materiałów niebezpiecznych również z uwzględnieniem rozwiązań intermodalnych. Transport broni, amunicji, czy materiałów wybuchowych wymaga kompleksowego podejścia, specjalistycznej wiedzy oraz odpowiedniego przygotowania technicznego.',
          paragraph_2:
            'Nasi kierowcy to wysoko wykwalifikowani fachowcy z długoletnią praktyką w zakresie transportu specjalistycznego, zarówno w kraju, jak i zagranicą. Cała kadra posiada wszelkie niezbędne uprawnienia do przewozu ładunków niebezpiecznych (m.in. certyfikat "Befähigungsschein nach parf 20 des Sprengstoffgestetzes"), bierze udział w programach i kursach zapewniających podniesienie bezpieczeństwa oraz osiągnięcie najbardziej ekonomicznego i ekologicznego prowadzenia pojazdów. '
        },
        section_4: {
          title: 'Standardy przewozu',
          paragraph_1:
            'Wdrożone przez nas procedury bezpieczeństwa i wypracowane praktyki właściwego obchodzenia się z ładunkami niebezpiecznymi dają naszym Klientom gwarancję stuprocentowej satysfakcji, czego najlepszym świadectwem są stałe zlecenia, zarówno od Klientów indywidualnych, jak i największych światowych armii.',
          paragraph_2:
            'W trakcie każdego transportu materiałów niebezpiecznych dbamy o:',
          icons: {
            icon_1: 'Zabezpieczenie transportu i właściwe pakowanie towarów',
            icon_2:
              'Przygotowaniu kierowców i personelu na wypadek sytuacji awaryjnych',
            icon_3: 'Opracowanie i dostarczenie wymaganych dokumentów',
            icon_4:
              'Zapewnienie idealnego stanu technicznego i wyposażenia pojazdów'
          }
        },
        section_5: {
          title: 'Potrzebujesz transportu materiałów niebezpiecznych?',
          paragraph: 'Chętnie odpowiemy na każde pytania!'
        }
      },
  
      /* Downloads page */
      downloads: {
        title: 'Do pobrania',
        documents: {
          c9001: {
            title: 'Certyfikat ISO 9001',
            files: [
              { file_name: 'certyfikat_9001_pol.pdf', title: 'PL' },
              { file_name: 'certyfikat_9001_ang.pdf', title: 'ANG' },
              { file_name: 'certyfikat_9001_niem.pdf', title: 'NIEM' }
            ]
          },
          cWSK: {
            title: 'Certyfikat WSK',
            files: [
              { file_name: 'certyfikat_WSK_pol.pdf', title: 'PL' },
              { file_name: 'certyfikat_WSK_ang.pdf', title: 'ANG' },
              { file_name: 'certyfikat_WSK_niem.pdf', title: 'NIEM' }
            ]
          },
          NCAGE: {
            title: 'Certyfikat NCAGE Code',
            files: [{ file_name: 'NCAGE_CODE.pdf', title: 'Pobierz' }]
          },
          licencja: {
            title: 'Licencja',
            files: [{ file_name: 'LICENCJA.pdf', title: 'Pobierz' }]
          },
          zezwolenie: {
            title: 'Zezwolenie',
            files: [{ file_name: 'ZEZWOLENIE.pdf', title: 'Pobierz' }]
          }
        }
      }
    },
  
    en: {
      links: {
        contact_us: 'Contact us',
        meet_us: 'Meet us',
        more: 'More',
        home: 'Home',
        about: {
          /* id of the element on the page (shown in page path): "Text in dropdown" */
          title: 'About',
          origins: 'Origins',
          values: 'Values',
          capabilities: 'Our capabilities',
          standards: 'Standards',
          team: 'Team'
        },
        services: 'Services',
        TCP: 'Transport of liquid chemicals',
        TMN: 'Transport of hazardous materials',
        contact: 'contact'
      },
      footer: {
        name:
          'BENSKI TRANSPORT © 2018 Transport specjalistyczny ADR, Transport międzynarodowy',
        email: 'lp.iksneb@oruib',
        privacy_policy: 'Privacy policy',
        legal: 'Legal'
      },
  
      /* Home page */
      home: {
        section_1: {
          title: 'Safely to the destination for over 30 years',
          paragraph:
            'Safety is the guiding principle of our Company. We make every effort to ensure that it is present at every stage of the order - we provide it both in relation to the people and transported goods.'
        },
        section_2: {
          title: 'About us - how did we start?',
          paragraph:
            'Founded in 1986, we have years of experience in the transportation industry. With every kilometer passed, we were getting closer to full perfection in the field of specialized transport.'
        },
        section_3: {
          title:
            'Do you need to transport hazardous materials or liquid chemicals?',
          paragraph: 'We will gladly answer any questions!'
        },
        section_4: {
          title: 'Our services',
          subtitle: 'We offer transport of explosives, weapons, and ammunition.',
          button_1: 'Transport of explosives',
          paragraph_1:
            'We also specialize in the transport of chemical products in tanks and containers of renowned manufacturers.',
          button_2: 'Transport of liquid chemicals',
          paragraph_2:
            'We have the highest-class specialist equipment, our vehicles have been equipped with GPS, and the entire fleet is regularly serviced at authorized points, allowing us to maintain an excellent technical condition of the trucks.'
        },
        section_5: {
          title: 'Social responsibility',
          paragraph_1:
            'Throughout many years of activity, Benski has been guided and still is by the principles of corporate social responsibility.',
          paragraph_2: 'This means that we pay special attention to:',
          boxes: {
            box_1: {
              title: 'Human resources',
              paragraph:
                'The team of our drivers consists of specialists who are trained in economical driving and reducing emissions. Cooperation with us is a guarantee of safety on many levels.'
            },
            box_2: {
              title: 'Protection of the environment',
              paragraph:
                'The fleet at our disposal is characterized by low pollution production and economical fuel consumption. All our vehicles meet strict emission standards, including EURO 5 and EURO 6.'
            },
            box_3: {
              title: 'Relation with Customers',
              paragraph:
                'We use state-of-the-art monitoring systems to guarantee effective delivery of cargo to our clients - regardless of the type of transported goods or destination.'
            }
          }
        },
        section_6: {
          /* Each element in array will be separated by new line on the page */
          title: ['BENSKI sp. z o.o. sp. k.', 'ul. Niegolewskich 5, 42-700 Lubliniec'],
          /* Each element will be new paragraph, if it is array, the elements will be separated by new line */
          paragraphs: [
            ['lp.iksneb@oruib'],
            'P: +48 34 351 30 27',
            'Our consultants are at your service',
            'We work from 8:00 to 16:00 on business days.'
          ]
        }
      },
  
      /* About page */
      about: {
        section_1: {
          title: 'About us - how did we start?',
          paragraph:
            'Founded in 1986, we have years of experience in the transportation industry. With every kilometer passed, we were getting closer to full perfection in the field of specialized transport.'
        },
        section_2: {
          title: 'The origins and values of Benski company',
          paragraph_1:
            'From the very beginning, the family nature of the company and local capital determine the values we are guided by.',
          paragraph_2:
            'We focus on the highest quality of provided services, which is why we recognize the needs of our clients and have always listened to their suggestions for several decades. This approach led to the rapid expansion of the company - dynamic development is in our case not only an empty slogan - it is the result of the full commitment of father and son, owners of the Benski Company.',
          paragraph_3:
            "Over 30 years of activity in the transport market have enabled us to achieve perfection in international shipping and to develop trust of both individual clients and the world's largest armies."
        },
        section_3: {
          title: 'Do you need to transport liquid chemicals?',
          paragraph: 'We will gladly answer any questions!'
        },
        section_4: {
          title: 'We care about the details',
          paragraph:
            'Each order completed over the last 30 years was treated by us with the greatest care and attention to the smallest detail at every stage of execution. We do not divide into important and less important projects, each of our several thousand orders annually receives the highest priority.'
        },
        section_5: {
          title: 'Our capabilities',
          paragraph:
            'Our skills are supported by high-quality technical facilities. Only such a combination enables the professional execution of several thousand orders a year. The Benski fleet allows for the organization of domestic and international transport of dangerous goods of all ADR classes, including class 1, that is materials and explosive objects.',
          examples: {
            title: 'We offer transport:',
            name_1: 'By Isotherms with a capacity of up to 1, 8 and 24 tons',
            name_2:
              'By tanks and IZO-containers with a capacity of 30000 - 34000 liters',
            name_3: 'By 20- and 40-foot containers',
            name_4: 'Goods under customs closures with the use of TIR Carnets'
          }
        },
        section_6: {
          title: 'Unchanging standards',
          paragraph_1:
            'Regardless of whether we transport 10 kg black powder to a warehouse located 100 km away, or multi-ton loads to customers in Turkey, we operate with the same procedures and quality of services provided.',
          paragraph_2:
            "Both the individual client and the world's largest armies have entrusted us with orders of varying complexity for years. In return, they receive a guarantee of professional and timely execution."
        },
        team: {
          title: 'Our team',
          section_1: {
            title: 'Logistics Department',
            names: [
              {
                name_1: 'Mateusz Mańka',
                name_2: 'Transport / shipping - tankers',
                line_1: 'E-mail: m.manka@benski.pl',
                line_2: 'Phone / Fax: +48 34 351 30 27',
                line_3: 'Mobile phone: +48 668 441 029',
                line_4: 'E-mail: ',
                line_5: 'lp.iksneb@aknam.m'
              },
              {
                name_1: 'Kamil Krawczyk',
                name_2: 'Transport / shipping - tankers',
                line_1: 'E-mail: k.krawczyk@benski.pl',
                line_2: 'Phone / Fax: +48 34 351 30 27',
                line_3: 'Mobile phone: +48 518 183 146',
                line_4: 'E-mail: ',
                line_5: 'lp.iksneb@kyzcwark.k'
              },
              {
                name_1: 'Andrzej Zoń',
                name_2: 'Transport / shipping - isotherm',
                line_1: 'E-mail: a.zon@benski.pl',
                line_2: 'Phone / Fax: +48 34 351 30 27',
                line_3: 'Mobile phone: +48 668 553 843',
                  line_4: 'E-mail: ',
                  line_5: 'lp.iksneb@noz.a'
              },
              {
                name_1: 'Logistic Manager / ',
                name_2: ' Vice-Chairman of the Board',
                bold_line_1: 'Michał Benski',
                line_2: 'E-mail: michal@benski.pl',
                line_3: 'Phone: +48 602 345 195',
                  line_4: 'E-mail: ',
                  line_5: 'lp.iksneb@lahcim'
              }
            ]
          },
          section_2: {
            title: 'Accounting department',
            names: [
              {
                name: 'Jolanta Wiśniowska',
                line_1: 'Phone: +48 34 356 12 01',
                line_2: 'Fax: +48 34 351 30 27',
                line_8: 'E-mail: jolanta.wisniowska@benski.pl',
                  line_6: 'E-mail: ',
                  line_7: 'lp.iksneb@akswoinsiw.atnaloj'
              },
              {
                name: 'Mariola Zozgórnik',
                line_1: 'Phone: +48 34 356 12 01',
                line_2: 'Fax: +48 34 351 30 27',
                line_8: 'E-mail: mariola.zozgornik@benski.pl',
                  line_6: 'E-mail: ',
                  line_7: 'lp.iksneb@kinrogzoz.aloiram'
              },
              {
                name: 'BENSKI sp. z o.o. sp. k.',
                line_1: 'ul. Strażacka 27',
                line_2: '42-700 Solarnia',
                line_3: 'NIP 5751886314',
                line_4: 'P: +48 34 351 30 27',
                line_5: 'E: biuro@benski.pl',
                  line_6: 'E: ',
                  line_7: 'lp.iksneb@oruib'
              },
              {
                name: 'Mailing address',
                line_1: 'BENSKI sp. z o.o. sp. k.',
                line_2: 'ul. Niegolewskich 5,',
                line_3: '42-700 Lubliniec'
              }
            ]
          }
        }
      },
  
      /* TCP page */
      TCP: {
        section_1: {
          title: 'Transport of liquid chemicals',
          paragraph:
            'Comprehensive approach and professionalism allow for the efficient execution of each project'
        },
        section_2: {
          title: 'Transport of chemical products',
          paragraph:
            'We offer transport of chemical products in tanks and containers of renowned manufacturers.'
        },
        section_3: {
          title: 'Many years of experience in the transport of liquid chemicals',
          paragraph_1:
            'National and international regulations impose a number of requirements and restrictions on the transport company.',
          paragraph_2:
            'Despite the high complexity of this type of order, our comprehensive approach and professionalism allow for the efficient execution of each project.',
          paragraph_3:
            'Transportation of liquid chemicals also requires the highest competences from drivers, and employees involved in the process, our team has all the rights to ensure full safety in the transport of liquid chemicals.'
        },
        section_4: {
          title: 'Transport standards',
          paragraph:
            'Strict maintenance of safety standards is the main principle that guides us when transporting liquid chemicals. Chemical loads have different properties, require specialized infrastructure, specific logistic procedures and appropriate conditions of transport, so each time we carry out such orders, we care about:',
          icons: {
            icon_1: 'Proper marking of tanks and vehicles',
            icon_2: 'Drivers and staff training',
            icon_3: 'Preparation and delivery of the required documentation',
            icon_4: 'Ensuring proper transport conditions and vehicle equipment'
          }
        },
        section_5: {
          title: 'Do you need to transport liquid chemicals?',
          paragraph: 'We will gladly answer any questions!'
        }
      },
  
      /* TMN page */
      TMN: {
        section_1: {
          title: 'Transport of explosives, weapons, and ammunition',
          paragraph:
            'A comprehensive approach and professionalism guarantee the safe transport of your goods.'
        },
        section_2: {
          title: 'Transport of hazardous materials',
          paragraph:
            'We have the highest-class specialist equipment for transporting explosives.'
        },
        section_3: {
          title: 'Comprehensive transport of weapons, ammunition, and explosives',
          paragraph_1:
            'Benski specializes in the transport of hazardous materials, taking into account intermodal solutions. Transportation of weapons, ammunition or explosives requires a comprehensive approach, specialist knowledge, and appropriate technical preparation.',
          paragraph_2:
            'Our drivers are highly qualified specialists with many years of experience in specialized transport, both domestically and internationally. All staff have all necessary permissions to carry dangerous goods (including the "Befähigungsschein nach parf 20 des Sprengstoffgestetzes" certificate), take part in programs and courses ensuring safety and achieving the most economical and environmentally-friendly driving.'
        },
        section_4: {
          title: 'Transport standards',
          paragraph_1:
            "The safety procedures we have implemented and the practices we have developed to properly handle dangerous goods give our customers 100% satisfaction, which is evident by the standing orders from both individual customers and the world's largest armies.",
          paragraph_2:
            'During each transport of hazardous materials we take care of:',
          icons: {
            icon_1: 'Securing transport and proper packaging of goods',
            icon_2:
              'Preparation of drivers and staff in the event of an emergency',
            icon_3: 'Development and delivery of required documents',
            icon_4:
              'Ensuring perfect technical condition and equipment of vehicles'
          }
        },
        section_5: {
          title: 'Do you need to transport hazardous materials?',
          paragraph: 'We will gladly answer any questions!'
        }
      },
  
      /* Downloads page */
      downloads: {
        title: 'Downloads',
        documents: {
          c9001: {
            title: 'ISO 9001 Certificate',
            files: [
              { file_name: 'certyfikat_9001_pol.pdf', title: 'PL' },
              { file_name: 'certyfikat_9001_ang.pdf', title: 'EN' },
              { file_name: 'certyfikat_9001_niem.pdf', title: 'DE' }
            ]
          },
          cWSK: {
            title: 'WSK Certificate',
            files: [
              { file_name: 'certyfikat_WSK_pol.pdf', title: 'PL' },
              { file_name: 'certyfikat_WSK_ang.pdf', title: 'EN' },
              { file_name: 'certyfikat_WSK_niem.pdf', title: 'DE' }
            ]
          },
          NCAGE: {
            title: 'NCAGE Code Certificate',
            files: [{ file_name: 'NCAGE_CODE.pdf', title: 'Download' }]
          },
          licencja: {
            title: 'License',
            files: [{ file_name: 'LICENCJA.pdf', title: 'Download' }]
          },
          zezwolenie: {
            title: 'Permission',
            files: [{ file_name: 'ZEZWOLENIE.pdf', title: 'Download' }]
          }
        }
      }
    },
  
    de: {
      links: {
        contact_us: 'Kontaktieren Sie uns',
        meet_us: 'Lerne Sie uns kennen',
        more: 'Erfahren Sie mehr!',
        home: 'Startseite',
        about: {
          title: 'Über uns',
          entstehung: 'Entstehung',
          werte: 'Werte',
          moglichkeiten: 'Unsere Möglichkeiten',
          standards: 'Standards',
          team: 'Team'
        },
        services: 'Dienstleistungen',
        TCP: 'Transport von flüssigen Chemikalien',
        TMN: 'Transport von Gefahrstoffen',
        contact: 'kontakt'
      },
      footer: {
        name:
          'BENSKI TRANSPORT © 2018 Transport specjalistyczny ADR, Transport międzynarodowy',
        email: 'lp.iksneb@oruib',
        privacy_policy: 'Privacy policy',
        legal: 'Legal'
      },
  
      /* Home page */
      home: {
        section_1: {
          title: 'Sicher ins Ziel seit über 30 Jahren',
          paragraph:
            'Sicherheit ist das Leitmotiv unseres Unternehmens. Wir bemühen uns, dass sie in jeder Phase der Bestellung vorhanden ist - sowohl in Bezug auf die Personen als auch auf die transportierten Güter.'
        },
        section_2: {
          title: 'Über uns - wie hat es angefangen?',
          paragraph:
            '1986 war die Zeit, als wir begannen, unsere ersten entscheidenden Schritte in der Transportbranche zu unternehmen. Mit jedem gefahrenen Kilometer kamen wir der Vollkommenheit im Bereich des Spezialtransports immer näher.'
        },
        section_3: {
          title:
            'Müssen Sie Gefahrstoffe oder flüssige Chemikalien transportieren?',
          paragraph: 'Wir antworten gerne auf jede Frage!'
        },
        section_4: {
          title: 'Unsere Dienstleistungen',
          subtitle:
            'Wir bieten den Transport von Sprengstoffen, Waffen und Munition an.',
          button_1: 'Transport von Sprengstoffen',
          paragraph_1:
            'Darüber hinaus sind wir spezialisiert auf den Transport von chemischen Produkten in Tanks und Containern namhafter Hersteller.',
          button_2: 'Transport flüssiger Chemikalien',
          paragraph_2:
            'Wir verfügen über eine erstklassige Spezialausrüstung, unsere Fahrzeuge sind mit einem GPS-Ortungssystem ausgestattet, und die gesamte Flotte wird regelmäßig an autorisierten Stellen gewartet, wodurch wir den ausgezeichneten technischen Zustand der Fahrzeuge erhalten.'
        },
        section_5: {
          title: 'Soziale Verantwortung',
          paragraph_1:
            'Seit vielen Jahren orientiert sich Benski an den Prinzipien der Corporate Social Responsibility.',
          paragraph_2:
            'Das bedeutet, dass wir besondere aufmerksamkeit auf folgende Punkte legen:',
          boxes: {
            box_1: {
              title: 'Personalabteilung',
              paragraph:
                'Unser Fahrerteam besteht aus Spezialisten, die über die Kompetenz verfügen, wirtschaftlich zu fahren und Abgase zu reduzieren. Die Zusammenarbeit mit uns bedeutet eine Garantie für Sicherheit auf vielen Ebenen.'
            },
            box_2: {
              title: 'Schutz der Umwelt',
              paragraph:
                'Unsere Flotte zeichnet sich durch eine schadstoffarme Produktion und einen sparsamen Kraftstoffverbrauch aus. Alle unsere Fahrzeuge erfüllen die strengen Abgasnormen, einschließlich EURO 5 und EURO 6.'
            },
            box_3: {
              title: 'Kundenbeziehung',
              paragraph:
                'Mit modernsten Überwachungssystemen stellen wir sicher, dass unsere Kunden ihre Ladung effektiv erhalten - unabhängig von der Art des Transportgutes und dem Bestimmungsort.'
            }
          }
        },
        section_6: {
          /* Each element in array will be separated by new line on the page */
          title: ['BENSKI sp. z o.o. sp. k.', 'ul. Niegolewskich 5, 42-700 Lubliniec'],
          /* Each element will be new paragraph, if it is array, the elements will be separated by new line */
          paragraphs: [
            ['lp.iksneb@oruib'],
            'T: +48 34 351 30 27',
            'Unsere Berater stehen Ihnen gerne zur Verfügung!',
            'An Werktagen arbeiten wir von 8:00 bis 16:00 Uhr.'
          ]
        }
      },
  
      /* About page */
      about: {
        section_1: {
          title: 'Über uns - wie hat es angefangen?',
          paragraph:
            '1986 war die Zeit, als wir begannen, unsere ersten entscheidenden Schritte in der Transportbranche zu unternehmen. Mit jedem gefahrenen Kilometer kamen wir der Vollkommenheit im Bereich des Spezialtransports immer näher.'
        },
        section_2: {
          title: 'Entstehung und Werte von Benskis Unternehmen',
          paragraph_1:
            'Von Anfang an hat der Familiencharakter des Unternehmens und des polnischen Kapitals die Werte bestimmt, die uns leiten.',
          paragraph_2:
            'Wir konzentrieren uns auf die höchste Qualität der erbrachten Dienstleistungen, deshalb lernen wir die Bedürfnisse unserer Kunden kennen und hören seit mehreren Jahrzehnten auf ihre Vorschläge. Dieser Ansatz hat zu einer raschen Expansion des Unternehmens geführt - die dynamische Entwicklung ist in unserem Fall nicht nur ein leerer Slogan - sondern der Effekt des vollen Engagements von Vater und Sohn, den Eigentümern der Firma Benski.',
          paragraph_3:
            'Mehr als 30 Jahre Tätigkeit auf dem Transportmarkt haben es uns ermöglicht, die Perfektion der internationalen Spedition zu erreichen und das Vertrauen sowohl der einzelnen Kunden als auch der größten Armeen der Welt zu gewinnen.'
        },
        section_3: {
          title: 'Müssen Sie flüssige Chemikalien transportieren?',
          paragraph: 'Für Fragen stehen wir Ihnen gerne zur Verfügung!'
        },
        section_4: {
          title: 'Wir kümmern uns um die Details.',
          paragraph:
            'Jeder Auftrag, der innerhalb von mehr als 30 Jahren abgeschlossen wurde, wurde von uns mit größter Sorgfalt und Aufmerksamkeit bis ins kleinste Detail in jeder Phase der Umsetzung behandelt. Wir unterteilen uns nicht in wichtige und weniger wichtige Projekte, jeder von den mehreren tausenden Aufträgen pro Jahr erhält die höchste Priorität.'
        },
        section_5: {
          title: 'Unsere Möglichkeiten',
          paragraph:
            'Unsere Kompetenzen werden durch hochwertige technische Einrichtungen unterstützt. Nur eine solche Kombination ermöglicht die professionelle Ausführung von mehreren tausend Aufträgen pro Jahr. Die Flotte von Benski ermöglicht die Organisation des nationalen und internationalen Transports von Gefahrstoffen aller ADR-Klassen, einschließlich der Klasse 1, d.h. Sprengstoffe.',
          examples: {
            title: 'Wir bieten Transport an:',
            name_1:
              'Isothermen mit einer Kapazität von bis zu 1, 8 und 24 Tonnen',
            name_2:
              'Tankwagen und IZO-Container mit einem Volumen von 30000 - 34000 Liter',
            name_3: '20 und 40 Grad Container',
            name_4: 'Waren unter Zollverschluss mit TIR-Carnets'
          }
        },
        section_6: {
          title: 'Unveränderte Standards',
          paragraph_1:
            'Unabhängig davon, ob wir 10 kg Schwarzpulver zu einem 100 km entfernten Lager transportieren oder mehrere Tonnen an Kunden in der Türkei liefern, arbeiten wir mit den gleichen Verfahren und der gleichen Servicequalität.',
          paragraph_2:
            'Sowohl Einzelkunden als auch die größten Armeen der Welt vertrauen uns seit Jahren Aufträge von unterschiedlicher Komplexität an, dafür erhalten sie die Gewähr für eine professionelle und termingerechte Ausführung.'
        },
        team: {
          title: 'Unser Team',
          section_1: {
            title: 'Logistik',
            names: [
              {
                name_1: 'Mateusz Mańka',
                name_2: 'Transport / Versand - Tanker',
                line_1: 'E-mail: m.manka@benski.pl',
                line_2: 'Tel. / Fax: +48 34 351 30 27',
                line_3: 'Handy: +48 668 441 029',
                  line_4: 'E-mail: ',
                  line_5: 'lp.iksneb@aknam.m'
              },
              {
                name_1: 'Kamil Krawczyk',
                name_2: 'Transport / Versand - Tanker',
                line_1: 'E-mail: k.krawczyk@benski.pl',
                line_2: 'Tel. / Fax: +48 34 351 30 27',
                line_3: 'Handy: +48 518 183 146',
                  line_4: 'E-mail: ',
                  line_5: 'lp.iksneb@kyzcwark.k'
              },
              {
                name_1: 'Andrzej Zoń',
                name_2: 'Transport / shipping - Isotherme',
                line_1: 'E-mail: a.zon@benski.pl',
                line_2: 'Tel. / Fax: +48 34 351 30 27',
                line_3: 'Handy: +48 668 553 843',
                  line_4: 'E-mail: ',
                  line_5: 'lp.iksneb@noz.a'
              },
              {
                name_1: 'Logistic Manager / ',
                name_2: ' Vice-Chairman of the Board',
                bold_line_1: 'Michał Benski',
                line_2: 'E-mail: michal@benski.pl',
                line_3: 'Tel.: +48 602 345 195',
                  line_4: 'E-mail: ',
                  line_5: 'lp.iksneb@lahcim'
              }
            ]
          },
          section_2: {
            title: 'Buchhaltung',
            names: [
              {
                name: 'Jolanta Wiśniowska',
                line_1: 'Tel.: +48 34 356 12 01',
                line_2: 'Fax: +48 34 351 30 27',
                line_8: 'E-mail: jolanta.wisniowska@benski.pl',
                  line_6: 'E-mail: ',
                  line_7: 'lp.iksneb@akswoinsiw.atnaloj'
              },
              {
                name: 'Mariola Zozgórnik',
                line_1: 'Tel.: +48 34 356 12 01',
                line_2: 'Fax: +48 34 351 30 27',
                line_8: 'E-mail: mariola.zozgornik@benski.pl',
                  line_6: 'E-mail: ',
                  line_7: 'lp.iksneb@kinrogzoz.aloiram'
              },
              {
                name: 'BENSKI sp. z o.o. sp. k.',
                line_1: 'ul. Strażacka 27',
                line_2: '42-700 Solarnia',
                line_3: 'NIP 5751886314',
                line_4: 'T: +48 34 351 30 27',
                line_5: 'E: biuro@benski.pl',
                  line_6: 'E: ',
                  line_7: 'lp.iksneb@oruib'
              },
              {
                name: 'Postanschrift',
                line_1: 'BENSKI sp. z o.o. sp. k.',
                line_2: 'ul. Niegolewskich 5,',
                line_3: '42-700 Lubliniec'
              }
            ]
          }
        }
      },
  
      /* TCP page */
      TCP: {
        section_1: {
          title: 'Transport von flüssigen Chemikalien',
          paragraph:
            'Umfassender Ansatz und Professionalität garantieren einen sicheren Transport Ihrer Waren.'
        },
        section_2: {
          title: 'Transport von chemischen Produkten',
          paragraph:
            'Wir bieten den Transport von chemischen Produkten in Tanks und Containern namhafter Hersteller an.'
        },
        section_3: {
          title:
            'Langjährige Erfahrung im Bereich des Transports von flüssigen Chemikalien',
          paragraph_1:
            'Nationale und internationale Vorschriften stellen in diesem Zusammenhang eine Reihe von Anforderungen und Befestigungen an das Transportunternehmen.',
          paragraph_2:
            'Trotz der hohen Komplexität dieser Art von Aufträgen ermöglichen unser umfassender Ansatz und unsere Professionalität eine effiziente Umsetzung jedes Projekts.',
          paragraph_3:
            'Die Beförderung von flüssigen Chemikalien erfordert auch die höchste Kompetenz von Fahrern und Mitarbeitern, die in den Prozess eingebunden sind. Unser Team verfügt über alle Lizenzen, um die volle Sicherheit beim Transport von flüssigen Chemikalien zu gewährleisten.'
        },
        section_4: {
          title: 'Transportnormen',
          paragraph:
            'Die strikte Einhaltung von Sicherheitsstandards ist das Leitmotiv beim Transport von flüssigen Chemikalien im unserem Unternehmen. Chemische Ladungen haben unterschiedliche Eigenschaften, erfordern eine spezielle Infrastruktur, spezielle logistische Verfahren und angemessene Transportbedingungen, so dass wir uns jedes Mal um diese Art von Bestellungen kümmern:',
          icons: {
            icon_1: 'Entsprechende Kennzeichnung von Tanks und Fahrzeugen',
            icon_2: 'Schulung von Fahrern und Personal',
            icon_3: 'Erstellung und Lieferung der erforderlichen Unterlagen',
            icon_4:
              'Sicherstellung angemessener Bedingungen für die Beförderung und Ausrüstung von Fahrzeugen.'
          }
        },
        section_5: {
          title: 'Müssen flüssige Chemikalien transportiert werden?',
          paragraph: 'Wir antworten gerne auf jede Frage!'
        }
      },
  
      /* TMN page */
      TMN: {
        section_1: {
          title: 'Transport von Sprengstoffen, Waffen und Munition',
          paragraph:
            'Umfassender Ansatz und Professionalität garantieren einen sicheren Transport Ihrer Waren.'
        },
        section_2: {
          title: 'Transport von Gefahrstoffen',
          paragraph:
            'Wir verfügen über die beste Spezialausrüstung für den Transport von Sprengstoffen.'
        },
        section_3: {
          title: 'Umfassender Transport von Waffen, Munition und Sprengstoffen',
          paragraph_1:
            'Benski ist spezialisiert auf den Transport von Gefahrstoffen, einschließlich intermodaler Lösungen. Der Transport von Waffen, Munition oder Sprengstoffen erfordert ein umfassendes Vorgehen, Fachwissen und eine angemessene technische Vorbereitung.',
          paragraph_2:
            'Unsere Fahrer sind hochqualifizierte Fachleute mit langjähriger Erfahrung im Bereich des Spezialtransports im In- und Ausland. Alle Mitarbeiter verfügen über alle notwendigen Qualifikationen, um gefährliche Güter zu transportieren (z.B. Zertifikat "Befähigungsschein nach parf 20 des Sprengstoffgestetzes"), nehmen an Programmen und Kursen zur Verbesserung der Sicherheit und zum sparsamsten und umweltfreundlichsten Fahren teil.'
        },
        section_4: {
          title: 'Transportnormen',
          paragraph_1:
            'Die von uns implementierten Sicherheitsverfahren und die entwickelten Praktiken für den ordnungsgemäßen Umgang mit Gefahrgütern garantieren unseren Kunden eine 100% Zufriedenheit, was sich am besten durch regelmäßige Bestellungen sowohl von Einzelkunden als auch von den größten Armeen der Welt zeigt.',
          paragraph_2: 'Bei jedem Gefahrguttransport kümmern wir uns darum:',
          icons: {
            icon_1:
              'Sicherstellung des Transports und der ordnungsgemäßen Verpackung der Waren',
            icon_2: 'Vorbereitung von Fahrern und Personal auf Notfälle',
            icon_3: 'Vorbereitung und Lieferung der erforderlichen Unterlagen',
            icon_4:
              'Sicherstellung, dass die Fahrzeuge in einwandfreiem Zustand sind.'
          }
        },
        section_5: {
          title: 'Sie müssen gefährliche Stoffe transportieren?',
          paragraph: 'Für Fragen stehen wir Ihnen gerne zur Verfügung!'
        }
      },
  
      /* Downloads page */
      downloads: {
        title: 'Downloads',
        documents: {
          c9001: {
            title: 'ISO 9001 Zertifikat',
            files: [
              { file_name: 'certyfikat_9001_pol.pdf', title: 'PL' },
              { file_name: 'certyfikat_9001_ang.pdf', title: 'EN' },
              { file_name: 'certyfikat_9001_niem.pdf', title: 'DE' }
            ]
          },
          cWSK: {
            title: 'WSK Zertifikat',
            files: [
              { file_name: 'certyfikat_WSK_pol.pdf', title: 'PL' },
              { file_name: 'certyfikat_WSK_ang.pdf', title: 'EN' },
              { file_name: 'certyfikat_WSK_niem.pdf', title: 'DE' }
            ]
          },
          NCAGE: {
            title: 'NCAGE Code Zertifikat',
            files: [{ file_name: 'NCAGE_CODE.pdf', title: 'Download' }]
          },
          licencja: {
            title: 'Lizenz',
            files: [{ file_name: 'LICENCJA.pdf', title: 'Download' }]
          },
          zezwolenie: {
            title: 'Genehmigung',
            files: [{ file_name: 'ZEZWOLENIE.pdf', title: 'Download' }]
          }
        }
      }
    }
  }
  
