import React from 'react'
import { ThemeProvider } from 'styled-components'

import SEO from 'components/SEO'
import Header from 'components/Header'
import Main from 'components/Main'
import Footer from 'components/Footer'

import { theme } from 'utils'

const Layout = ({
  pageTitle,
  pageDescription,
  location,
  children,
  locale,
  footerLight
}) => (
  <React.Fragment>
    <SEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pathname={location.pathname}
    />
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Header locale={locale} pathname={location.pathname} theme={theme} />
        <Main>{children}</Main>
        <Footer locale={locale} footerLight={footerLight} />
      </React.Fragment>
    </ThemeProvider>
  </React.Fragment>
)

export default Layout
