import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import '../../css/bootstrap.css'
import '../../css/stack-interface.css'
import '../../css/iconsmind.css'
import '../../css/theme.scss'
import '../../css/flickity.css'

import logoLight from '../../img/benski_logo_light.png'
import logoDark from '../../img/benski_logo_dark.png'

import texts from '../../constants/texts'
import paths from '../../constants/paths'

export default class Header extends React.Component {
  state = { windowWidth: 0 }
  componentDidMount () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  getPath = language => {
    const { locale, pathname } = this.props
    const id = Object.keys(paths[locale]).reduce(
      (p, c, i, arr) => (paths[locale][arr[i]].path === pathname ? arr[i] : p)
    )
    return paths[language][id].path
  }

  getAboutLink = id => Object.keys(texts[this.props.locale].links.about)[id]

  render () {
    const { locale, pathname } = this.props

    return (
      <div className='nav-container' id='header'>
        <div className='bar bar--sm visible-xs'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-3 col-sm-2'>
                <AniLink
                  duration={1}
                  style={{ marginLeft: '20px' }}
                  cover
                  direction='up'
                  bg='#230d54'
                  to={paths[locale]['/'].path}
                >
                  <img className='logo' alt='logo' src={logoDark} />
                  {/* <img className='logo logo-light' alt='logo' src={logoLight} /> */}
                </AniLink>
              </div>
              <div className='col-xs-9 col-sm-10 text-right'>
                <a
                  href='#'
                  className='hamburger-toggle'
                  data-toggle='#menu1;hidden-xs hidden-sm'
                  onClick={() => {
                    document
                      .getElementById('menu1')
                      .classList.toggle('hidden-xs')
                  }}
                >
                  <i className='icon hidden-sm icon--sm stack-interface stack-menu' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav
          id='menu1'
          className={`bar bar-1 hidden-xs bar--transparent bg--light`}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-md-2 col-sm-4 hidden-xs'>
                <div className='bar__module'>
                  <AniLink
                    duration={1}
                    cover
                    direction='up'
                    bg='#230d54'
                    to={paths[locale]['/'].path}
                  >
                    {/* <img className='logo logo-dark' alt='logo' src={logoDark} /> */}
                    <img className='logo ' alt='logo' src={logoLight} />
                  </AniLink>
                </div>
              </div>
              <div className='col-md-10 col-sm-8 text-right text-left-xs'>
                <div className='bar__module'>
                  <ul className='menu-horizontal'>
                    <li>
                      <AniLink
                        duration={1}
                        style={{ marginLeft: '20px' }}
                        cover
                        direction='up'
                        bg='#230d54'
                        to={paths[locale]['/'].path}
                      >
                        {texts[locale].links.home}
                      </AniLink>
                    </li>

                    {this.state.windowWidth < 768 ? (
                      <li>
                        <AniLink
                          duration={1}
                          style={{ marginLeft: '20px' }}
                          cover
                          direction='up'
                          bg='#230d54'
                          to={paths[locale]['/about/'].path}
                        >
                          {texts[locale].links.about[this.getAboutLink(0)]}
                        </AniLink>
                      </li>
                    ) : (
                      <li id='about-link' className='dropdown'>
                        <AniLink
                          duration={1}
                          style={{ marginLeft: '20px' }}
                          cover
                          direction='up'
                          bg='#230d54'
                          to={paths[locale]['/about/'].path}
                          // id='dropdown__trigger'
                          className='dropdown__trigger'
                          onMouseOver={() => {
                            document
                              .getElementById('about-link')
                              .classList.add('dropdown--active')

                            document
                              .getElementById('services-link')
                              .classList.remove('dropdown--active')
                          }}
                        >
                          {texts[locale].links.about[this.getAboutLink(0)]}
                        </AniLink>
                        <div
                          className='dropdown__container text-left'
                          onMouseLeave={() => {
                            document
                              .getElementById('about-link')
                              .classList.remove('dropdown--active')
                          }}
                        >
                          <div className='container'>
                            <div className='row'>
                              <div className='dropdown__content col-md-3'>
                                <ul className='menu-vertical'>
                                  <li>
                                    <AniLink
                                      duration={1}
                                      cover
                                      direction='up'
                                      bg='#230d54'
                                      to={`${
                                        paths[locale]['/about/'].path
                                      }#${this.getAboutLink(1)}`}
                                    >
                                      {
                                        texts[locale].links.about[
                                          this.getAboutLink(1)
                                        ]
                                      }
                                    </AniLink>
                                  </li>
                                  <li>
                                    <AniLink
                                      duration={1}
                                      cover
                                      direction='up'
                                      bg='#230d54'
                                      to={`${
                                        paths[locale]['/about/'].path
                                      }#${this.getAboutLink(2)}`}
                                    >
                                      {
                                        texts[locale].links.about[
                                          this.getAboutLink(2)
                                        ]
                                      }
                                    </AniLink>
                                  </li>
                                  <li>
                                    <AniLink
                                      duration={1}
                                      cover
                                      direction='up'
                                      bg='#230d54'
                                      to={`${
                                        paths[locale]['/about/'].path
                                      }#${this.getAboutLink(3)}`}
                                    >
                                      {
                                        texts[locale].links.about[
                                          this.getAboutLink(3)
                                        ]
                                      }
                                    </AniLink>
                                  </li>
                                  <li>
                                    <AniLink
                                      duration={1}
                                      cover
                                      direction='up'
                                      bg='#230d54'
                                      to={`${
                                        paths[locale]['/about/'].path
                                      }#${this.getAboutLink(4)}`}
                                    >
                                      {
                                        texts[locale].links.about[
                                          this.getAboutLink(4)
                                        ]
                                      }
                                    </AniLink>
                                  </li>
                                  <li>
                                    <AniLink
                                      duration={1}
                                      to={`${
                                        paths[locale]['/about/'].path
                                      }#${this.getAboutLink(5)}`}
                                      cover
                                      direction='up'
                                      bg='#230d54'
                                    >
                                      {
                                        texts[locale].links.about[
                                          this.getAboutLink(5)
                                        ]
                                      }
                                    </AniLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    <li id='services-link' className='dropdown'>
                      <span
                        id='dropdown__trigger'
                        style={{ marginLeft: '20px' }}
                        className='dropdown__trigger'
                      >
                        {texts[locale].links.services}
                      </span>
                      <div
                        className='dropdown__container text-left'
                        onMouseLeave={() => {
                          document
                            .getElementById('services-link')
                            .classList.remove('dropdown--active')
                        }}
                      >
                        <div className='container'>
                          <div className='row'>
                            <div className='dropdown__content col-md-4'>
                              <ul className='menu-vertical'>
                                <li>
                                  <AniLink
                                    duration={1}
                                    cover
                                    direction='up'
                                    bg='#230d54'
                                    to={paths[locale]['/TMN/'].path}
                                  >
                                    {texts[locale].links.TMN}
                                  </AniLink>
                                </li>
                                <li>
                                  <AniLink
                                    duration={1}
                                    cover
                                    direction='up'
                                    bg='#230d54'
                                    to={paths[locale]['/TCP/'].path}
                                  >
                                    {texts[locale].links.TCP}
                                  </AniLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <AniLink
                        duration={1}
                        style={{ marginLeft: '20px' }}
                        cover
                        direction='up'
                        bg='#230d54'
                        to={`${paths[locale]['/downloads/'].path}`}
                      >
                        {texts[locale].downloads.title}
                      </AniLink>
                    </li>
                    <li>
                      <div className='bar__module'>
                        <AniLink
                          duration={1}
                          style={{ marginLeft: '20px' }}
                          cover
                          direction='up'
                          bg='#230d54'
                          to={`${paths[locale]['/about/'].path}#${
                            texts[locale].links.contact
                          }`}
                          className='btn btn--sm btn--primary type--uppercase'
                        >
                          <span className='btn__text'>
                            {texts[locale].links.contact_us}
                          </span>
                        </AniLink>
                      </div>
                    </li>
                    {Object.keys(paths).map(
                      (key, i) =>
                        key !== locale && (
                          <li key={i}>
                            <AniLink
                              duration={1}
                              style={{ marginLeft: '20px' }}
                              cover
                              direction='up'
                              bg='#230d54'
                              to={this.getPath(key)}
                            >
                              {key}
                            </AniLink>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}
