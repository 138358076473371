import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import texts from '../../constants/texts'
import paths from '../../constants/paths'

export default ({ locale, footerLight }) => (
  <footer
    id='footer'
    className={`text-center-xs space--xs ${footerLight ? '' : 'bg--dark'}`}
  >
    <div className='container'>
      <div className='row'>
        <div className='col-sm-7'>
          <ul className='list-inline'>
            <li>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={paths[locale]['/about/'].path}
              >
                <span className='h6 type--uppercase'>
                  <p>
                    {
                      texts[locale].links.about[
                        Object.keys(texts[locale].links.about)[0]
                      ]
                    }
                  </p>
                </span>
              </AniLink>
            </li>
            <li>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={paths[locale]['/TCP/'].path}
              >
                <span className='h6 type--uppercase'>
                  <p>{texts[locale].links.TCP}</p>
                </span>
              </AniLink>
            </li>
            <li>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={paths[locale]['/TMN/'].path}
              >
                <span className='h6 type--uppercase'>
                  <p>{texts[locale].links.TMN}</p>
                </span>
              </AniLink>
            </li>
            <li>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={`${paths[locale]['/about/'].path}#${
                  texts[locale].links.contact
                }`}
              >
                <span className='h6 type--uppercase'>
                  <p>{texts[locale].links.contact}</p>
                </span>
              </AniLink>
            </li>
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-7'>
          <span className='type--fine-print'>{texts[locale].footer.name}</span>
          {/* <a className='type--fine-print' href='#'>
            {texts[locale].footer.privacy_policy}
          </a>
          <a className='type--fine-print' href='#'>
            {texts[locale].footer.legal}
          </a> */}
        </div>
        <div className='col-sm-5 text-right text-center-xs'>
          <span
            className='adres type--fine-print'
          >
            {texts[locale].footer.email}
          </span>
        </div>
      </div>
    </div>
  </footer>
)
