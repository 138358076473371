module.exports = {
  /**
   *  language: {
   *    path_id: {
   *      pageTitle: 'Title of the page shown on browser tab'
   *      path: 'page/path/in/this/language'
   *    }
   *    ...
   *  }
   */

  pl: {
    '/': {
      pageTitle: 'Benski Transport',
      path: '/'
    },
    '/about/': {
      pageTitle: 'O nas',
      path: '/o-nas'
    },
    '/TCP/': {
      pageTitle: 'Transport chemii płynnej',
      path: '/transport-chemii-plynnej'
    },
    '/TMN/': {
      pageTitle: 'Transport materiałów niebezpiecznych',
      path: '/transport-materialow-niebezpiecznych'
    },
    '/downloads/': {
      pageTitle: 'Do pobrania',
      path: '/do-pobrania'
    }
  },

  en: {
    '/': {
      pageTitle: 'Benski Transport',
      path: '/en'
    },
    '/about/': {
      pageTitle: 'About',
      path: '/en/about'
    },
    '/TCP/': {
      pageTitle: 'Transport of liquid chemicals',
      path: '/en/transport-of-liquid-chemicals'
    },
    '/TMN/': {
      pageTitle: 'Transport of hazardous materials',
      path: '/en/transport-of-hazardous-materials'
    },
    '/downloads/': {
      pageTitle: 'Downloads',
      path: '/en/downloads'
    }
  },

  de: {
    '/': {
      pageTitle: 'Benski Transport',
      path: '/de'
    },
    '/about/': {
      pageTitle: 'Über uns',
      path: '/de/uber-uns'
    },
    '/TCP/': {
      pageTitle: 'Transport von flüssigen Chemikalien',
      path: '/de/transport-von-flussigen-chemikalien'
    },
    '/TMN/': {
      pageTitle: 'Transport von Gefahrstoffen',
      path: '/de/transport-von-gefahrstoffen'
    },
    '/downloads/': {
      pageTitle: 'Downloads',
      path: '/downloads'
    }
  }
}
