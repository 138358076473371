import React from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'
import media from 'media'

const Container = styled(Box)`
  max-width: 100%;

  ${media.greaterThan('sm')`
    max-width: 41rem;
  `} ${media.greaterThan('md')`
    max-width: 61rem;
  `} ${media.greaterThan('lg')`
    max-width: 84.5rem;
  `};
`

Container.defaultProps = {
  mx: 'auto',
  px: 4
}

export default props => <Container {...props} />
